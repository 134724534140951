import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import { useUserStore } from '~/stores/user'

import type {
  EventCategory,
  EventLib,
} from '~/types/Segment/SegmentEventLibrary/SegmentEventLibrary'
import type { IdentifyData } from '~/types/Segment/SegmentIdentifyLibrary'

/**
 * @deprecated use `~/composables/Segment/` composables instead
 */
export default defineComponent({
  computed: {
    ...mapState(useUserStore, { USER_ID: 'id', IS_LOGGED_IN: 'IS_LOGGED_IN' }),
  },
  methods: {
    /**
     * @deprecated use `~/composables/Segment/useSegmentIdentify.ts`
     *
     * Used to associate user login data within Segment. Implements Segment's
     * `identify` function seen on the
     * {@link https://segment.com/docs/connections/spec/identify/|segment identify documentation}.
     *
     * @param {object} userData - User data to associate with segment tracking.
     */
    identifyLogin(userData?: IdentifyData): void {
      const trackUserId = userData?.user_id || this.USER_ID

      // don't want to send `user_id` as part of the userData object
      if (userData && userData.user_id) delete userData.user_id

      this.$segment.identify(trackUserId.toString(), userData)
    },
    /**
     * @deprecated use `~/composables/Segment/useSegmentTrack.ts`
     *
     * General purpose tracking function used to send event tracking to Segment.
     * See the
     * {@link https://segment.com/docs/connections/spec/track/|segment track function documentation}
     * for more info.
     *
     * @param {string} name - The event name.
     * @param {object} [args] - An object containing any additional data properties
     * to be sent along with the request to Segment.
     */
    trackSegmentEvent<T extends keyof EventLib & EventCategory>(
      name: T,
      ...args: EventLib[T] extends Record<string, never>
        ? [additionalData?: EventLib[T]]
        : [additionalData: EventLib[T]]
    ): void {
      // only tracked if user is logged in
      if (!this.IS_LOGGED_IN) return

      this.$segment.track(name, args[0] ?? {})
    },
    /**
     * @deprecated use `~/composables/Segment/useSegmentReset.ts`
     *
     * Calls Segment's `reset` function to reset user tracking upon logging out.
     * See {@link https://segment.com/docs/getting-started/04-full-install/#using-analyticsreset|Segment's reset function documentation}
     * for more info.
     */
    resetSegment(): void {
      this.$segment.reset()
    },
  },
})
